<template>
  <div class="flashing-product">
    <h3>Nos produits phares</h3>
    <div class="fancy-portfolio-four lg-container">
      <div class="container">
        <div class="pb-130 md-pb-90 sm-pb-10">
          <div class="mixitUp-container d-flex flex-wrap" data-aos="fade-right">
            <div
                v-for="item in products"
                :key="item.id"
                class="col-6 col-lg-3 p-2"
            >
              <div class="portfolio-block-two position-relative">
                <img
                    :src="item.img"
                    alt=""
                    class="w-100 h-100 tran4s img-meta"
                />
              </div>
              <!-- /.portfolio-block-two -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const products = [
  {
    id: 16,
    img: `/images/altela-img/produits-phare/filets-desamiantage.png`,
    title: "Core WordPress",
    tag: "Development, Plugin",
  },
  {
    id: 17,
    img: `/images/altela-img/produits-phare/filets-reception-purge.png`,
    title: "Platform Audit",
    tag: "Development, Branding",
  },
  {
    id: 18,
    img: `/images/altela-img/produits-phare/pince-altelateral.png`,
    title: "Medieval origins",
    tag: "Development, Design"
  },
  {
    id: 20,
    img: `/images/altela-img/produits-phare/filets-fosse.png`,
    title: "Motivation defining Moment",
    tag: "Development, Design"
  },
  {
    id: 21,
    img: `/images/altela-img/produits-phare/filets-sanglier.png`,
    title: "Our experience WordCamp",
    tag: "Development, Branding"
  },
  {
    id: 22,
    img: `/images/altela-img/produits-phare/sommert-onde-soi.png`,
    title: "Lets catch up amd",
    tag: "Development, Design"
  },
  {
    id: 23,
    img: `/images/altela-img/produits-phare/filets-ignifuges.png`,
    title: "Lets catch up amd",
    tag: "Development, Design",
  },
  {
    id: 24,
    img: `/images/altela-img/produits-phare/download-catalogue.png`,
    title: "Lets catch up amd",
    tag: "Development, Design"
  }
]
</script>
<style scoped>
.portfolio-block-two {
  background: transparent;
  transition: .4s ease-in-out;
}

.portfolio-block-two:hover {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.50);
}

h3 {
  text-align: center;
  font-family: Panton;
  font-style: italic;
  font-size: 39px;
  margin: 2rem 0 4rem 0;
  color: #003853;
}

.lg-container .container {
  max-width: 1633px;
}

.mixitUp-container {
  row-gap: 1rem;
}

@media screen and (max-width: 810px) {
  .flashing-product {
    //display: none;
  }

  h3 {
    font-size: 20px;
    margin: 2rem 0;
  }
}
</style>