<template>
  <div class="slide-video">
    <div class="fancy-portfolio-four lg-container">
      <div class="container">
        <div class="pb-130 md-pb-90 sm-pb-20">
          <div class="titleVideoSlide d-flex justify-content-between flex-column flex-lg-row align-items-center gap-3 mb-4">
            <h3>Nos vidéos</h3>
            <a href="https://www.youtube.com/channel/UCaX8LKglmzv09vrt8ktf_Cw/videos" target="_blank" class="seeAllVideo">Voir tous nos vidéos <i class="fa-solid fa-arrow-right-long"></i>
            </a>
          </div>
          <div class="mixitUp-container gutter-space-two d-flex flex-wrap">
            <div
                v-for="(item, imageIndex) in data"
                :key="item.id"
                class="mix"
                data-aos="fade-right"
            >
              <div class="portfolio-block-two position-relative">
                <iframe width="420" height="315" :src="item.link">
                </iframe>
              </div>
              <!-- /.portfolio-block-two -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted} from "vue";

const data = [
  {
    id: 1,
    img: `/images/altela-img/nos-videos/youtube3.png`,
    title: "Medieval origins",
    tag: "Development, Design",
    video: true,
    category: "branding-3",
    link: "https://www.youtube.com/embed/YJoLJqCjuLc"
  },
  {
    id: 2,
    img: `/images/altela-img/nos-videos/youtube2.png`,
    title: "Medieval origins",
    tag: "Development, Design",
    video: true,
    category: "branding-3",
    link: "https://www.youtube.com/embed/gkuOtuN308M"
  },
  {
    id: 3,
    img: `/images/altela-img/nos-videos/youtube1.png`,
    title: "Medieval origins",
    tag: "Development, Design",
    video: true,
    category: "branding-3",
    link: "https://www.youtube.com/embed/piiGCzsjlOk"
  },
]
</script>
<style scoped>
.fancy-portfolio-four {
  background-color: #F9E6E7;
}

.container {
  padding-top: 8rem;
}

.titleVideoSlide h3 {
  font-family: Panton;
  text-transform: uppercase;
  font-style: italic;
  font-size: 39px;
  color: #D9000D;
}

.seeAllVideo {
  padding: 12px 15px;
  border: 1px solid #D9000D;
  color: #D9000D;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
}

.seeAllVideo:hover {
  color: #fff;
  background-color: #D9000D;
}

.portfolio-block-two .fancybox {
  top: 30%;
  right: 45%;
}

.portfolio-block-two {
  border-radius: 10px;
}

@media screen and (max-width: 810px) {
  .container {
    padding-top: 2rem;
  }

  .titleVideoSlide h3 {
    font-size: 20px;
  }

  .seeAllVideo {
    font-size: 15px;
  }
}
</style>