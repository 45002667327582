<template>
  <div class="banner">
    <img class="imgBanner" src="/images/altela-img/pastille-filetfrancais.png" alt="pastille-filetfrancais">
    <div class="d-flex justify-content-around divBodyBanner">
      <h3 class="">Commandez vos produits & Equipement en ligne !</h3>
      <div class="d-flex justify-content-between align-items-center divServiceBanner">
        <div class="serviceBannerItem">
          <img src="/images/altela-img/expedition-white.png" alt="expedition-white">
          <span>Expedition rapide</span>
        </div>
        <div class="serviceBannerItem">
          <img src="/images/altela-img/devis-white.png" alt="devis-white">
          <span>Devis immédiat</span>
        </div>
        <div class="serviceBannerItem">
          <img src="/images/altela-img/fabrication-white.png" alt="fabrication-white">
          <span>Fabrication sur - mesure</span>
        </div>
        <div class="serviceBannerItem">
          <img src="/images/altela-img/france-flag-white.png" alt="france-flag-white">
          <span>Fabrication 100% Francaise</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

</script>
<style scoped>
.banner {
  background-color: #093888;
  position: relative;
  margin-bottom: 5rem;
}

.imgBanner {
  position: absolute;
  top: -17%;
  left: 6%;
}

h3, span {
  color: #fff;
  text-transform: uppercase;
}

h3 {
  font-family: Panton;
  font-size: 39px;
  font-style: italic;
}

.divBodyBanner {
  padding: 2rem 13rem 2rem 25rem;
  align-items: center;
}

.divServiceBanner {
  column-gap: 4rem;
}

.divServiceBanner span {
  font-size: 15px;
}

.serviceBannerItem {
  line-height: normal;
  text-align: center;
  font-family: Panton;
}

.serviceBannerItem img {
  margin: auto;
}

@media screen and (max-width: 1600px) {
  h3 {
    font-size: 20px;
  }
  .divBodyBanner {
    padding: 2rem 1rem 2rem 29rem;
  }
}

@media screen and (max-width: 1366px) {
  .divBodyBanner {
    padding: 2rem 1rem 2rem 20rem;
  }
}

@media screen and (max-width: 810px) {
  .banner {
    padding: 7rem 0 1rem 0;
    margin-top: 5rem;
  }

  .imgBanner {
    width: 40%;
    top: -25%;
    left: 31%
  }

  h3 {
    font-size: 20px;
    text-align: center;
  }

  .divBodyBanner {
    padding: 0;
  }

  .divServiceBanner {
    display: none!important;
  }
}
</style>